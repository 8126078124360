import clsx from "classnames";
import classes from "./styles.module.scss";
import Search from "@/assets/Search.svg";
import Close from "@/assets/Close.svg";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { placeholders } from "@/data/placeholders";
import jobsData from "../../../.sourceflow/jobs.json";
import { useLink } from "@/hooks/useLink";
import { router } from "@/router";
import { specialisms_helper } from "@/helpers/specialisms_helper";
import slug from "slug";

export const AppSidebar = ({showSidebar}) => {
  const titleRef = useRef();
  const linkRef = useRef();
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [radius, setRadius] = useState("");
  const locale = useLocale();
  const link = useLink();
  const specialisms = specialisms_helper.fetch({ parent: null });

  useEffect(() => {
    if (jobsData && "jobs" in jobsData && Array.isArray(jobsData.jobs)) {
      if (titleRef.current && "getElementsByTagName" in titleRef.current) {
        const title = titleRef.current.childNodes.item(0);
        const [append, _val, prepend] = title.innerText.split(" ");

        title.innerText = `${append} ${
          jobsData.jobs.filter((job) => new Date(job.expires_at) > new Date()).length
        } ${prepend}`;
      }

      if (linkRef.current && "getElementsByTagName" in linkRef.current) {
        const title = linkRef.current.childNodes.item(0);
        const [append, _val, prepend] = title.innerText.split(" ");

        title.innerText = `${append} ${
          jobsData.jobs.filter((job) => new Date(job.expires_at) > new Date()).length
        } ${prepend}`;
      }
    }

    setVisible(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams({ query, location, radius });

    window.location.href = `${router["jobs"][locale]}?${params.toString()}`;
  };

  return (
    <>
    {showSidebar &&
    <aside className={clsx(classes.sidebar, "d-none d-xl-flex py-5", { show, visible })}>
      <div onClick={() => setShow(!show)}>
        <a ref={linkRef} href="#" onClick={(e) => e.preventDefault()}>
          <DynamicText path={`sidebar.form.title.${locale}`} className="me-2" />
          {show ? <Close /> : <Search />}
        </a>
      </div>
      <div className="pe-5">
        <Form className="p-4" onSubmit={handleSubmit}>
          <DynamicText path={`sidebar.form.title.${locale}`} className="h3 mb-2 text-white fw-medium" />
          <Form.Group className="mb-2">
            <Form.Label htmlFor="sidebar-query" className="mb-0">
              <DynamicText path={`jobs.search.query.label.${locale}`} />
            </Form.Label>
            <Form.Control
              value={query}
              id="sidebar-query"
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              placeholder={placeholders[`jobs.search.query.placeholder.${locale}`]}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="sidebar-location" className="mb-0">
              <DynamicText path={`jobs.search.location.label.${locale}`} />
            </Form.Label>
            <Form.Control
              value={location}
              id="sidebar-location"
              type="text"
              onChange={(e) => setLocation(e.target.value)}
              placeholder={placeholders[`jobs.search.location.placeholder.${locale}`]}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="sidebar-radius" className="mb-0">
              <DynamicText path={`jobs.search.radius.label.${locale}`} />
            </Form.Label>
            <Form.Select className="form-select" value={radius} onChange={(e) => setRadius(e.target.value)} id="sidebar-radius">
              <option value="5">5 km</option>
              <option value="10">10 km</option>
              <option value="20">20 km</option>
              <option value="30">30 km</option>
              <option value="40">40 km</option>
              <option value="50">50 km</option>
            </Form.Select>
          </Form.Group>
          <Button
            size="lg"
            className="px-4 d-flex flex-row justify-content-center align-items-center"
            onClick={handleSubmit}
          >
            <DynamicText path={`jobs.search.button.${locale}`} tag="span" className="me-2" />
            <Search className="flex-shrink-0" />
          </Button>
        </Form>
        <div className={clsx(classes.sidebar__sectors, "p-4 mt-3")}>
          <DynamicText path={`sidebar.sectors.title.${locale}`} className="h4" />
          <Stack className="flex-row flex-wrap py-2" gap={2}>
            {specialisms.map((i) => (
              <Button key={i.id} href={link(`jobs.specialism`, slug(i.name))} variant="primary">
                {i.name}
              </Button>
            ))}
          </Stack>
        </div>
      </div>
    </aside>
    }
    </>
  );
};

AppSidebar.defaultProps = {
  showSidebar: true,
};